<template>
  <div class="merchant-container">
    <div style="height: 50px">
      <div style="float: left; margin: 5px 0 0 20px">
        <el-button type="info" size="small" @click="jumpPage">返回活动列表</el-button>
      </div>
      <div style="float: right">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="套餐名称:">
            <el-input
                v-model.trim="queryForm.name"
                clearable
                placeholder="套餐名称"
                size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="user_id"
          label="日期">
        <template #default="{row}">
          <span>{{ row.create_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="user"
          label="会员">
      </el-table-column>
      <el-table-column
          align="center"
          prop="activity_name"
          label="套餐名称">
      </el-table-column>
      <el-table-column
          align="center"
          label="优惠券">
        <template #default="{row}">
          <span v-for="(item,index) in row.activity_value.coupon" :key="index" class="site">
            {{ item.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="activity_value.balance"
          label="余额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="activity_value.integral"
          label="积分">
      </el-table-column>
      <!--      <el-table-column align="center" label="操作" width="250">
              <template #default="{ row }">
                <el-button type="text" @click="handleEdit(row)">修改</el-button>
                <el-button type="text" @click="handleDelete(row)">删除</el-button>
              </template>
            </el-table-column>-->
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        align="center"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </div>
  <!--  </el-card>-->
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetTopPackageLog} from "@/request/api";

export default {
  name: 'awardList',
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    jumpPage() {
      this.$router.push({
        name: 'rechargePackage',
      })
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetTopPackageLog(this.queryForm).then(res => {
        if (res.code = 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style>
.site {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  color: #409eff;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #409eff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 5px;
}
</style>

